import React from "react"
import {
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from "@material-ui/core"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import { format as DateFnsFormat } from "date-fns"
import { BlogRecord } from "../api/types"
import { green, red } from "@material-ui/core/colors"
import EditIcon from "@material-ui/icons/Edit"
import { Link } from "gatsby"
import AdminBlogTablePagination from "./admin-blog-table-pagination"

interface AdminBlogTableProps {
  blogs: BlogRecord[]
  loading: boolean
  nextToken: string | null
  currentPage: number
  pageHandler: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    publishedCol: {
      textAlign: "center",
    },
  })
)

const blogsToTableRows = (blogs: BlogRecord[]) => {
  return blogs.map((blog, idx) => {
    const created = new Date(blog.SK)
    const dateFormat = "yyyy-MM-dd HH:mm:ss OOOO"
    const publishedColor = blog.published ? green[500] : red[800]

    return (
      <TableRow key={idx}>
        <TableCell>{blog.title}</TableCell>
        <TableCell>{blog.path}</TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <FiberManualRecordIcon style={{ color: publishedColor }} />
        </TableCell>
        <TableCell>{DateFnsFormat(created, dateFormat)}</TableCell>
        <TableCell>
          <IconButton
            size="small"
            component={Link}
            to={`/admin/edit/${encodeURIComponent(blog.id)}`}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  })
}

export default function AdminBlogTable(props: AdminBlogTableProps) {
  const { blogs, loading, nextToken, currentPage, pageHandler } = props
  const classes = useStyles()

  let rows
  if (loading && blogs.length === 0) {
    rows = (
      <TableRow>
        <TableCell colSpan={5}>Loading...</TableCell>
      </TableRow>
    )
  } else {
    if (blogs.length === 0) {
      rows = (
        <TableRow>
          <TableCell colSpan={5}>No blogs found!</TableCell>
        </TableRow>
      )
    } else {
      rows = blogsToTableRows(blogs)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Path</TableCell>
            <TableCell className={classes.publishedCol}>Published</TableCell>
            <TableCell>Created on</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={5}
              count={-1}
              onChangePage={pageHandler}
              page={currentPage}
              rowsPerPage={10}
              rowsPerPageOptions={[]}
              labelDisplayedRows={() => {
                return ""
              }}
              ActionsComponent={props => (
                <AdminBlogTablePagination {...props} nextToken={nextToken} />
              )}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
