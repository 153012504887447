import React from "react"
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"

interface AdminBlogTablePaginationProps extends TablePaginationActionsProps {
  nextToken: string | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
      "& button": {
        marginLeft: theme.spacing(2),
      },
    },
  })
)

export default function AdminBlogTablePagination(
  props: AdminBlogTablePaginationProps
) {
  const { page, onChangePage, nextToken } = props

  const classes = useStyles()

  const handlePrevPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1)
  }

  const handleNextPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1)
  }

  return (
    <div className={classes.pagination}>
      <Button
        variant="text"
        startIcon={<KeyboardArrowLeft />}
        onClick={handlePrevPageClick}
        disabled={page === 0}
      >
        Prev
      </Button>
      <Button
        variant="text"
        endIcon={<KeyboardArrowRight />}
        onClick={handleNextPageClick}
        disabled={nextToken === null}
      >
        Next
      </Button>
    </div>
  )
}
