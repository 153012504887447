import React, { useState, useEffect, useCallback } from "react"
import { Button, Grid } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

import { BlogRecord } from "../api/types"

import { Amplify, API, graphqlOperation, Auth } from "aws-amplify"
import * as Queries from "../api/queries"

import awsconfig from "../aws-exports"
import AdminBlogTable from "../components/admin-blog-table"
import Loading from "../components/loading"
Amplify.configure(awsconfig)

type BlogRecordPage = BlogRecord[]

type TokenPageMap = (string | null)[]

const getBlogs = async (nextToken: string | null) => {
  const session = await Auth.currentSession()
  const owner = session.getIdToken().decodePayload().sub
  const params = {
    owner,
    nextToken,
  }

  const {
    data: {
      listBlogs: { items: result, nextToken: token },
    },
  } = (await API.graphql(
    graphqlOperation(Queries.adminListBlogs, params)
  )) as any

  return {
    blogs: result,
    nextToken: token,
  }
}

export default function AdminBlogList() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [blogs, setBlogs] = useState<BlogRecord[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pages, setPages] = useState<BlogRecordPage[]>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [tokenPageMap, setTokenPageMap] = useState<TokenPageMap>(["(first)"])

  const handlePageChange = useCallback(
    async (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      if (newPage < currentPage || pages[newPage]) {
        setBlogs(pages[newPage])
        setNextToken(tokenPageMap[newPage + 1] || null)
        setCurrentPage(newPage)
      } else {
        setLoading(true)
        const { blogs: items, nextToken: token } = await getBlogs(nextToken)
        if (items.length > 0) {
          setBlogs(items)
          setNextToken(token)
          setPages(prev => {
            const newPages = prev.slice()
            newPages[newPage] = items
            return newPages
          })
          setTokenPageMap(prev => {
            const tokens = prev.slice()
            tokens[newPage + 1] = token
            return tokens
          })
          setCurrentPage(newPage)
        } else {
          if (newPage !== 0) {
            setNextToken(null)
            setTokenPageMap(prev => {
              const tokens = prev.slice()
              tokens[newPage] = null
              return tokens
            })
          }
        }
        setLoading(false)
      }
    },
    [currentPage, pages, tokenPageMap, nextToken]
  )

  useEffect(() => {
    const loadBlogs = async () => {
      setLoading(true)
      const { blogs: items, nextToken: token } = await getBlogs(null)

      if (items.length > 0) {
        setBlogs(items)
        setNextToken(token)
        setPages(prev => {
          const newPages = prev.slice()
          newPages[0] = items
          return newPages
        })
        setTokenPageMap(prev => {
          const tokens = prev.slice()
          tokens[1] = token
          return tokens
        })
        setCurrentPage(0)
      }
      setLoading(false)
    }
    loadBlogs()
  }, [])

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            component={Link}
            to="/admin/add"
          >
            Add blog
          </Button>
        </Grid>
        <Grid item xs={12}>
          <AdminBlogTable
            blogs={blogs}
            loading={loading}
            nextToken={nextToken}
            currentPage={currentPage}
            pageHandler={handlePageChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
