import React, { useEffect, useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { format as DateFnsFormat } from "date-fns"
import { useParams } from "@reach/router"
import { Helmet } from "react-helmet"

import { Amplify, API, graphqlOperation, Storage } from "aws-amplify"

import { FullBlogRecord } from "../api/types"
import * as Mutations from "./../api/mutations"
import * as Queries from "./../api/queries"
import { red } from "@material-ui/core/colors"

import awsconfig from "../aws-exports"
import { MarkdownRenderers } from "../utils/helpers"
import ReactMarkdown from "react-markdown"
import Loading from "../components/loading"

Amplify.configure(awsconfig)

enum GRAPHQL_AUTH_MODE {
  API_KEY = "API_KEY",
  AWS_IAM = "AWS_IAM",
  OPENID_CONNECT = "OPENID_CONNECT",
  AMAZON_COGNITO_USER_POOLS = "AMAZON_COGNITO_USER_POOLS",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    actions: {
      padding: theme.spacing(2),
    },
    datePicker: {
      paddingRight: theme.spacing(2),
    },
    cancelButton: {
      backgroundColor: red[400],
      color: "white",
      marginRight: theme.spacing(2),
      "&:hover": {
        backgroundColor: red[700],
      },
    },
    tagContainer: {
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
    },
    tagChip: {
      margin: theme.spacing(0.5),
    },
  })
)

const DATE_FORMAT = "y-MM-dd hh:mm:ss OOOO"
const MAX_TAGS = 10

const blobToString = (blob: Blob) => {
  return new Promise(resolve => {
    const fr = new FileReader()

    fr.onload = () => {
      return resolve(fr.result)
    }

    fr.readAsText(blob, "utf-8")
  })
}

const loadBlog = async (id: string): Promise<null | any> => {
  const params = { id: decodeURIComponent(id) }

  const {
    data: {
      blogsById: { items: blogs },
    },
  } = (await API.graphql({
    ...graphqlOperation(Queries.getBlogByIdWithTags, params),
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as any

  if (blogs.length === 0) {
    return null
  }

  return blogs[0]
}

export default function BlogForm() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [published, setPublished] = useState<boolean>(true)
  const [inputs, setInputs] = useState<{ [key: string]: string }>({})
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [inputBlog, setInputBlog] = useState<FullBlogRecord | null>(null)
  const [cancelled, setCancelled] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)
  const [tags, setTags] = useState<string[]>([])
  const [inputTags, setInputTags] = useState<string[]>([])
  const [addTags, setAddTags] = useState<string[]>([])
  const [deleteTags, setDeleteTags] = useState<string[]>([])
  const [previewOpen, setPreviewOpen] = useState<boolean>(false)

  const { id } = useParams()

  useEffect(() => {
    const loadBlogById = async () => {
      setLoading(true)

      try {
        const theBlog = await loadBlog(id)

        if (theBlog) {
          const result = (await Storage.get(theBlog.s3Body, {
            download: true,
          })) as { [key: string]: any }

          theBlog.body = await blobToString(result.Body)
          setInputBlog(theBlog)
          fillBlog(theBlog)
        } else {
          setInputBlog(null)
          // TODO: some kind of error
        }
      } catch (err) {
        setCancelled(true)
      }

      setLoading(false)
    }

    if (id) {
      loadBlogById()
    }
  }, [id])

  const fillBlog = (inputBlog: FullBlogRecord) => {
    if (inputBlog) {
      const blogInputs: { [key: string]: any } = {
        title: inputBlog.title,
        path: inputBlog.path,
        body: inputBlog.body,
        blurb: inputBlog.blurb || "",
      }
      setInputs(blogInputs)
      setPublished(inputBlog.published ? true : false)

      if (inputBlog.tags) {
        const blogTags = inputBlog.tags.items.map(tag => {
          return tag.tag.PK
        })

        setTags(blogTags)
        setInputTags(blogTags)
      }
    }
  }

  const handlePublishedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPublished(event.target.checked)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newInputs = { ...inputs }
    let value = event.target.value
    if (event.target.name === "path") {
      value = value.toLowerCase()
      if (value[0] === "/") {
        value = value.substring(1)
      }
    } else if (event.target.name === "blurb") {
      if (value.length > 500) {
        value = value.substring(0, 500)
      }
    }
    newInputs[event.target.name] = value
    setInputs(newInputs)
  }

  const handleTagInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newInputs = { ...inputs }
    const value = event.target.value
    if (/,$/.test(value)) {
      const tag = value.substring(0, value.length - 1)

      if (inputBlog && inputTags.indexOf(tag) === -1) {
        const newAddTags = addTags.slice()
        newAddTags.push(tag)
        setAddTags(newAddTags)
      }

      if (inputBlog && deleteTags.indexOf(tag) !== -1) {
        setDeleteTags(deleteTags => deleteTags.filter(t => tag !== t))
      }

      const newTags = tags.slice()
      newTags.push(tag)
      setTags(newTags)

      newInputs[event.target.name] = ""
    } else {
      newInputs[event.target.name] = value
    }
    setInputs(newInputs)
  }

  const handleTagDelete = (tag: string) => {
    setTags(tags => tags.filter(t => t !== tag))
    if (inputTags.indexOf(tag) !== -1) {
      const delTags = deleteTags.slice()
      delTags.push(tag)
      setDeleteTags(delTags)
    } else if (addTags.indexOf(tag) !== -1) {
      setAddTags(addTags => addTags.filter(t => t !== tag))
    }
  }

  const handleCancel = () => {
    const message =
      "Your changes will be lost. Are you sure you want to cancel?"
    if (window.confirm(message)) {
      setCancelled(true)
    }
  }

  const handlePreviewClose = () => {
    setPreviewOpen(false)
  }

  const handlePreviewOpen = () => {
    setPreviewOpen(true)
  }

  const submitForm = async () => {
    if (validate()) {
      const input: { [key: string]: any } = {
        title: inputs.title,
        path: inputs.path,
        body: inputs.body,
        published: published ? 1 : 0,
        tags: inputBlog ? inputTags : tags,
      }
      if (inputs.blurb && inputs.blurb.length > 0) {
        input.blurb = inputs.blurb
      } else {
        input.blurb = null
      }

      if (inputBlog && inputBlog.id) {
        input.id = inputBlog.id
        input.PK = inputBlog.PK
        input.SK = inputBlog.SK
        input.addTags = addTags
        input.deleteTags = deleteTags
      }

      try {
        if (inputBlog) {
          await API.graphql(graphqlOperation(Mutations.updateBlog, { input }))
        } else {
          await API.graphql(graphqlOperation(Mutations.saveBlog, { input }))
        }

        const savedStr = input.id ? "updated" : "created"

        // dispatch({
        //   type: BlogContextActions.SET_FLASH_MESSAGE,
        //   payload: {
        //     type: FlashMessageType.SUCCESS,
        //     message: (
        //       <span>
        //         <em>{input.title}</em> {savedStr}.
        //       </span>
        //     ),
        //   },
        // });

        setSaved(true)
      } catch (err) {
        if (/^Lambda:/.test(err.errors[0].errorType)) {
          try {
            const error = JSON.parse(err.errors[0].message)
            if (error.code === "ValidationError") {
              setErrors({
                ...error.fields,
                global: "Please fix the highlighted errors.",
              })
            } else {
              setErrors({ global: "Error saving entry. Please try again." })
            }
          } catch (e) {
            setErrors({ global: "Error saving entry. Please try again." })
          }
        } else {
          alert("There was an error saving the entry...")
        }
      }
    }
  }

  const validate = () => {
    const title = inputs.title ? inputs.title.trim() : ""
    const path = inputs.path ? inputs.path.trim() : ""
    const body = inputs.body ? inputs.body.trim() : ""

    const errors: { [key: string]: string } = {}

    if (title.length === 0) {
      errors.title = "Title missing"
    }
    if (path.length === 0) {
      errors.path = "Path missing"
    } else if (!/^[a-z0-9-_.]+(\/[a-z0-9-_.]+)*$/.test(path)) {
      errors.path =
        "Path can contain letters, numbers, underscores, hyphens, periods, or forward slashes"
    }
    if (body.length === 0) {
      errors.body = "Body missing"
    }

    setErrors(errors)

    if (Object.keys(errors).length > 0) {
      errors.global = "Please fix the highlighted errors."
      return false
    } else {
      return true
    }
  }

  let globalError = null
  if (errors.global) {
    globalError = (
      <Typography variant="body1" color="error">
        {errors.global}
      </Typography>
    )
  }

  const classes = useStyles()

  let timestamps = null
  if (id && inputBlog) {
    const created = new Date(inputBlog.SK)
    const updated = new Date(inputBlog.updated)
    timestamps = (
      <Grid item xs={12}>
        <Typography variant="body2">
          <strong>Created:</strong> {DateFnsFormat(created, DATE_FORMAT)}
          <br />
          <strong>Updated:</strong> {DateFnsFormat(updated, DATE_FORMAT)}
        </Typography>
      </Grid>
    )
  }

  const previewDialog = (
    <Dialog maxWidth="lg" onClose={handlePreviewClose} open={previewOpen}>
      <DialogTitle>Preview</DialogTitle>
      <DialogContent dividers>
        <ReactMarkdown
          source={inputs.body || ""}
          escapeHtml={false}
          renderers={MarkdownRenderers()}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePreviewClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )

  if (cancelled || saved) {
    navigate("/admin")
    return null
  }

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      {previewDialog}
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Title"
              name="title"
              value={inputs.title || ""}
              helperText={errors.title || ""}
              error={errors.title ? true : false}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Path"
              name="path"
              value={inputs.path || ""}
              helperText={errors.path || ""}
              error={errors.path ? true : false}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              label="Blurb"
              name="blurb"
              value={inputs.blurb || ""}
              helperText={errors.blurb || ""}
              error={errors.blurb ? true : false}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={10}
              label="Body"
              name="body"
              value={inputs.body || ""}
              helperText={errors.body || ""}
              error={errors.body ? true : false}
              onChange={handleInputChange}
            />
            <Button
              variant="text"
              color="secondary"
              onClick={handlePreviewOpen}
            >
              Preview
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Tags"
              name="tags"
              value={inputs.tags || ""}
              helperText={errors.tags || ""}
              disabled={tags.length >= MAX_TAGS}
              onChange={handleTagInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.tagContainer}>
              {tags.map((tag, idx) => {
                return (
                  <Chip
                    label={tag}
                    key={idx}
                    id={`tag-${tag}`}
                    color="primary"
                    onDelete={() => {
                      handleTagDelete(tag)
                    }}
                    className={classes.tagChip}
                  />
                )
              })}
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={published}
                  onChange={handlePublishedChange}
                  color="default"
                />
              }
              label="Published"
            />
          </Grid>
          {timestamps}
        </Grid>
      </Paper>
      <Grid
        className={classes.actions}
        container
        spacing={2}
        justify="flex-end"
        alignItems="center"
      >
        <Grid item>{globalError}</Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.cancelButton}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={submitForm}>
            Save
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
