import React, { useContext, useEffect } from "react"
import { Router } from "@reach/router"
import { Amplify, Auth } from "aws-amplify"
import awsconfig from "../aws-exports"
import { BlogContext, BlogContextActions } from "../components/blog-context"
import { AmplifyAuthenticator } from "@aws-amplify/ui-react"
import AdminBlogList from "../views/admin-blog-list"
import BlogForm from "../views/blog-form"

Amplify.configure(awsconfig)

export default function Admin() {
  const { state, dispatch } = useContext(BlogContext)
  const { loggedIn } = state

  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (user) {
          dispatch({
            type: BlogContextActions.SET_LOGGED_IN,
            payload: {
              loggedIn: true,
            },
          })
        }
      } catch (err) {}
    }

    if (window) {
      checkUser()
    }
  }, [loggedIn, dispatch])

  let content = <AmplifyAuthenticator />
  if (state.loggedIn) {
    content = (
      <Router basepath="/admin">
        <AdminBlogList path="/" />
        <BlogForm path="/add" />
        <BlogForm path="/edit/:id" />
      </Router>
    )
  }

  return content
}
