export const saveBlog = /* GraphQL */ `
  mutation SaveBlog($input: SaveBlogInput!) {
    saveBlog(input: $input) {
      PK
      SK
      path
      id
      title
      s3Body
      published
    }
  }
`

export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog2($input: UpdateBlogInput!) {
    updateBlog2(input: $input) {
      PK
      SK
      path
      id
      title
      blurb
      s3Body
      published
      updated
    }
  }
`
